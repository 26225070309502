<template>
  <transition name="Transition__opacity-fade">
    <div
      class="BaseItemDelete__modal"
    >
      <div class="BaseItemDelete__modal-bg" />
      <div class="BaseItemDelete__modal-content column">
        <label class="fs-20 fw-med mb-21">
          {{ formattedDeleteText }}
        </label>
        <div class="row mb-21">
          <base-button
            @click="$emit('delete')"
            class="BaseItemDelete__modal-btn bg-blue fc-white mr-10"
            id="Test__ItemDeleteButton"
            data-cy="Test__ItemDeleteButton"
          >
            {{ confirmText }}
          </base-button>
          <base-button
            @click="$emit('cancel')"
            class="BaseItemDelete__modal-btn bg-light fc-white"
          >
            No
          </base-button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
// Components
import BaseButton from './base-button.vue'

export default {
  name: 'BaseItemDelete',

  components: {
    BaseButton
  },

  props: {
    confirmText: {
      type: String,
      required: false,
      default: 'Yes, Delete Invoice'
    },
    deleteText: {
      type: String,
      required: false,
      default: ''
    },
    invoice: {
      type: Object,
      required: false,
      default: null
    }
  },

  created () {
    if (this.invoice) {
      this.formattedDeleteText = `Are you sure you would like to delete invoice ${this.invoice.load_number}?`
      if (this.invoice.approved_date) {
        this.alertWarning('Cannot delete invoices that have been previously approved')
        this.$emit('cancel')
      }
    }
  },

  data () {
    return {
      formattedDeleteText: this.deleteText,
    }
  },

  // Easier to access <body> via a bit of JS than making some confusing CSS hack
  mounted () {
    document.body.style.overflow = 'hidden'
  },

  beforeDestroy() {
    document.body.style.overflow = 'visible'
  },
}
</script>


<style lang="sass">
.BaseItemDelete
  $block: &

  &__modal
    height: 100%
    left: 0
    position: fixed
    top: 0
    width: 100%
    z-index: 3

  &__modal-bg
    background-color: $text-light
    height: 100%
    opacity: 0.5
    position: absolute
    width: 100%

  &__modal-btn
    width: rem(170px)

  &__modal-content
    background-color: $white
    border-radius: $border-radius
    box-shadow: $modal-shadow
    margin: rem(176px) auto 0
    max-width: rem(540px)
    padding: rem(31px) rem(39px)
    position: relative
    width: 100%
    z-index: 4
</style>