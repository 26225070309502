var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "Transition__opacity-fade" } }, [
    _c("div", { staticClass: "BaseItemDelete__modal" }, [
      _c("div", { staticClass: "BaseItemDelete__modal-bg" }),
      _c("div", { staticClass: "BaseItemDelete__modal-content column" }, [
        _c("label", { staticClass: "fs-20 fw-med mb-21" }, [
          _vm._v("\n        " + _vm._s(_vm.formattedDeleteText) + "\n      ")
        ]),
        _c(
          "div",
          { staticClass: "row mb-21" },
          [
            _c(
              "base-button",
              {
                staticClass: "BaseItemDelete__modal-btn bg-blue fc-white mr-10",
                attrs: {
                  id: "Test__ItemDeleteButton",
                  "data-cy": "Test__ItemDeleteButton"
                },
                on: {
                  click: function($event) {
                    return _vm.$emit("delete")
                  }
                }
              },
              [_vm._v("\n          " + _vm._s(_vm.confirmText) + "\n        ")]
            ),
            _c(
              "base-button",
              {
                staticClass: "BaseItemDelete__modal-btn bg-light fc-white",
                on: {
                  click: function($event) {
                    return _vm.$emit("cancel")
                  }
                }
              },
              [_vm._v("\n          No\n        ")]
            )
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }